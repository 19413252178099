import React from 'react';

import { format2decimals } from '../lib/utils';

const Total = ({ total, currency }) => {
  return (
    <>
      {total.allowancecharge?.map((allowancecharge, index) => <div key={index} className="allowancechargewrapper">
        {allowancecharge?.amount && <div className="allowancecharge">
          <div>{allowancecharge.chargeindicator ? 'Zuschlag' : 'Rabatt'}</div>
          <div>{allowancecharge.reason} {`${allowancecharge.basisamount ? `Basis ${format2decimals(allowancecharge.basisamount, currency)}: ` : ' '}`}
            {format2decimals(allowancecharge.amount, currency)} MwSt {format2decimals(allowancecharge.taxpercent)}%
          </div>
        </div>}
      </div>)}
      {total.logisticsservicecharge?.map((logisticsservicecharge, index) => <div key={index} className="logisticsservicechargewrapper">
        {logisticsservicecharge?.amount && <div className="logisticsservicecharge">
          <div>Logistik</div>
          <div>{logisticsservicecharge.reason} {format2decimals(logisticsservicecharge.amount, currency)}
            {logisticsservicecharge.taxpercent ? ` MwSt ${format2decimals(logisticsservicecharge.taxpercent)}%` : ''}
          </div>
        </div>}
      </div>)}
      {(total.taxexemption1 || total.taxexemption1code) && <div className="taxexemptionreason">
        <div>Steuerbefreiung {format2decimals(total.tax1percent)}%</div>
        <div>{total.taxexemption1code} {total.taxexemption1}</div>
      </div>}
      {(total.taxexemption2 || total.taxexemption2code) && <div className="taxexemptionreason">
        <div>Steuerbefreiung {format2decimals(total.tax2percent)}%</div>
        <div>{total.taxexemption2code} {total.taxexemption2}</div>
      </div>}

      <table className="total">
        <tr className="tableheader">
          <th className="label">Netto</th>
          {parseInt(total.tax1percent || 0, 10) !== 0 && <th className="label">MwSt {format2decimals(total.tax1percent)}%</th>}
          {parseInt(total.tax2percent || 0, 10) !== 0 && <th className="label">MwSt {format2decimals(total.tax2percent)}%</th>}
          <th className="label">Brutto</th>
          {parseInt(total.prepaidamount, 10) > 0 && <th className="label">Anzahlung</th>}
          {parseInt(total.prepaidamount, 10) > 0 && <th className="label">zu zahlen</th>}
        </tr>
        <tr className="subtotal">
          <td className="value">{format2decimals(total.taxexclusiveamount, currency)}</td>
          {parseInt(total.tax1percent || 0, 10) !== 0 && <td className="value">{format2decimals(total.tax1amount, currency)}</td>}
          {parseInt(total.tax2percent || 0, 10) !== 0 && <td className="value">{format2decimals(total.tax2amount,currency)}</td>}
          <td className="brutto">{format2decimals(total.taxinclusiveamount,currency)}</td>
          {parseInt(total.prepaidamount, 10) > 0 && <td className="value">{format2decimals(total.prepaidamount,currency)}</td>}
          {parseInt(total.prepaidamount, 10) > 0 && <td className="payable">{format2decimals(total.payableamount,currency)}</td>}
        </tr>
      </table>
    </>
  )
}

export default Total
