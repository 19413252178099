import React, { useEffect, useState } from 'react';

import { uploadFiles } from './lib/utils';

import DnD from './dnd/DnD';
import Error from './Error';
import Customer from './widgets/Customer';
import ErrorBoundary from './widgets/ErrorBoundary';
import Footer from './widgets/Footer';
import Notes from './widgets/Notes';
import Payment from './widgets/Payment';
import Positions from './widgets/Positions';
import References from './widgets/References';
import Supplier from './widgets/Supplier';
import Total from './widgets/Total';

import './App.css';

const App = () => {
  const urlQParams = new URLSearchParams(window.location.search);
  const urlParams = new URLSearchParams(atob(urlQParams.get('_q')));
console.log(urlParams);
  const [invoice, setInvoice] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [message, setMessage] = useState('');

  const clip = async () => {
    if (message) {
      return;
    }

    try {
      const c = await navigator.clipboard.read();
      try {
        const res = await c[0].getType('text/plain');
        const text = await res.text();
        if (text) {
          if (text.startsWith('data:application/pdf;base64')) {
            return;
          }
          const json = JSON.parse(text);
          if (json.error) {
            setMessage(json.error);
          }
          setInvoice(json);

          navigator.clipboard.writeText('');
        }
      } catch (e) {
        console.error('clip error', e);
      }
    } catch (e) {
      console.error('clip', e);
    }
  }


  window.addEventListener("focus", (event) => {
    clip();
  });

  useEffect(() => {
    clip();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (files, type) => {
    if (type === 'pdf') {
      setPdf(files);
      setInvoice(null);
    } else {
      uploadFiles(files, json => {
        if (json.error) {
          setMessage(json.error);
        }
        setInvoice(json);
        setPdf(null);
      });
    }
  };

  const {
    customer = {}, supplier = {}, notes = [], positions = [], total = {}, payment = [], duedate,
    currency, document = {}, paymentinformation, paymentnote, XMLdata, schema
  } = invoice || {};

  let logo = '';
  if (schema?.includes('xRechnung')) {
    logo = '/img/xrechnung-logo.png';
  }
  if (schema?.includes('zugferd') || schema?.includes('factur-x')) {
    logo = '/img/zugferd-logo.jpg';
  }

  return (message ?
    <Error message={message} xml={XMLdata} />
    :
    <ErrorBoundary>
      {!urlParams.has('id') ?
        <form>
          <DnD
            onFilesSelected={handleChange}
          />
        </form>
        :
        <div className="invoice-id" />
      }
      {pdf && <embed
        style={{
          width: '100%',
          height: '100%',
        }}
        type='application/pdf'
        src={pdf}
      />}
      {invoice ?
        <div className="paper">
          <div className="header">
            <div className="title">Rechnung</div>
            {logo && <img className="logo" src={logo} alt="Logo" />}
            <Supplier supplier={supplier} />
          </div>

          <Customer customer={customer} />

          <div className="content">
            <References invoice={invoice} />
            <Notes notes={notes} />
          </div>

          <hr className="hr-top" />

          <Positions positions={positions} currency={currency} />
          <Total total={total} currency={currency} />

          <Payment
            payment={payment}
            paymentinformation={paymentinformation}
            paymentnote={paymentnote}
            supplier={supplier}
            duedate={duedate}
            currency={currency}
          />

          <Footer supplier={supplier} document={document} />
        </div>
        :
        <div className="loading">
          {urlParams.has('id') ? <div className="loading-spinner" /> : ''}
        </div>
      }
    </ErrorBoundary>
  );
}

export default App;
